import { useState } from "react";
import MenuItems from "./MenuItems";
import downArrow from "../images/downArrow.svg";
import upArrow from "../images/upArrow.svg";

export default function MenuItemsDropDown({
  name,
  subs,
  lang,
  colors,
  setModalOpen,
}) {
  let [dropDownView, SetDropDownView] = useState(false);

  return (
    <div
      className={dropDownView ? `menu-drop-down-container` : ""}
      style={dropDownView ? { borderColor: colors.secondary_font } : null}
    >
      <div
        className="drop-down-header"
        style={{ color: colors.primary_font, background: colors.secondary }}
        onClick={() => {
          SetDropDownView(!dropDownView);
        }}
      >
        <p>{name}</p>
        <img
          className="drow-down-img"
          src={dropDownView ? upArrow : downArrow}
          alt=""
        />
      </div>
      <div className={dropDownView ? `drop-down-main` : ""}>
        {dropDownView
          ? subs.map((item) => {
              return item.enable ? (
                <MenuItems
                  key={item.id}
                  id={item.id}
                  name={item.name[lang]}
                  note={item[lang]}
                  lang={lang}
                  havePrice={item.have_price}
                  extraPrices={item.sub_price}
                  price={item.price}
                  img={`https://menuapi.mubatech.com/images/subs`}
                  colors={colors}
                  setModalOpen={setModalOpen}
                />
              ) : null;
            })
          : null}
      </div>
    </div>
  );
}
