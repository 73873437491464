export default function PopUp(probs) {
  function handleClickOutside(event) {
    if (event.target.id === "popUp") {
      probs.setTrigger(false);
      probs.setModalOpen(false);
    }
  }

  return probs.trigger ? (
    <div id="popUp" className="popup-conatainer" onClick={handleClickOutside}>
      {probs.children}
    </div>
  ) : (
    ""
  );
}
