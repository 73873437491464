import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { useEffect, useState } from "react";

export default function SlideShow({ siteId, num_slides }) {
  const [allImages, setAllImages] = useState([]);
  useEffect(() => {
    setAllImages(createImageList());
  }, []);
  function createImageList() {
    let list = [];
    for (var i = 1; i <= num_slides; i++) {
      list.push(
        `https://menuapi.mubatech.com/images/sliders/${siteId}-0${i}.jpg`
      );
    }
    return list;
  }

  return (
    <div className="menu-carousel-container">
      <Carousel
        autoPlay={true}
        infiniteLoop={true}
        showThumbs={false}
        showArrows={false}
        showStatus={false}
      >
        {allImages.map((item, index) => {
          return (
            <div
              key={"slide" + index}
              className="menu-carousel-image-container"
            >
              <img
                className="menu-carousel-image"
                id={"scroll" + index}
                src={item}
                alt="thing"
              />
            </div>
          );
        })}
      </Carousel>
    </div>
  );
}
