import { useState } from "react";
import ItemViewer from "./ItemViewer";
import PopUp from "./PopUp";
import defaultImage from "../images/default.png";

export default function MenuItems({
  id,
  name,
  note,
  lang,
  havePrice,
  extraPrices,
  price,
  img,
  colors,
  setModalOpen,
}) {
  let [showItemViewer, setShowItemViewer] = useState(false);

  function getMaxAndMinPrice(priceArray) {
    let smallest = parseFloat(priceArray[0].price);
    priceArray.forEach((e) => {
      if (parseFloat(smallest) > parseFloat(e.price)) {
        smallest = parseFloat(e.price);
      }
    });
    let biggest = parseFloat(priceArray[0].price);
    priceArray.forEach((e) => {
      if (parseFloat(biggest) < parseFloat(e.price)) {
        biggest = parseFloat(e.price);
      }
    });

    return `${smallest.toLocaleString("en-US")} 
    - 
    ${biggest.toLocaleString("en-US")} `;
  }

  return (
    <div>
      <div
        className="menu-items"
        style={{ background: colors.secondary }}
        onClick={() => {
          setShowItemViewer(true);
          setModalOpen(true);
        }}
      >
        <div className="menu-items-info">
          <p className="menu-items-name" style={{ color: colors.primary_font }}>
            {name}
          </p>
          <p
            className="menu-items-note"
            style={{ color: colors.secondary_font }}
          >
            {note}
          </p>

          {havePrice === "1" || havePrice === true || havePrice === 1 ? (
            extraPrices.length === 0 ? null : (
              <p
                className="menu-items-price"
                style={{ color: colors.secondary_font }}
              >
                {`IQD ${getMaxAndMinPrice(extraPrices)}`}
              </p>
            )
          ) : (
            <p
              className="menu-items-price"
              style={{ color: colors.secondary_font }}
            >
              {"IQD " + parseFloat(price).toLocaleString("en-US")}
            </p>
          )}
        </div>

        <div className="menu-items-img-container">
          {img ? (
            <img
              src={img + `/thumbnail/${id}.jpg`}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = defaultImage;
              }}
              alt={name + " img"}
              className="menu-items-img"
              loading="lazy"
            />
          ) : null}
        </div>
      </div>
      <PopUp
        trigger={showItemViewer}
        setTrigger={setShowItemViewer}
        setModalOpen={setModalOpen}
      >
        <ItemViewer
          img={img + `/${id}.jpg`}
          name={name}
          note={note}
          lang={lang}
          price={price}
          havePrice={havePrice}
          extraPrices={extraPrices}
          colors={colors}
          setShowItemViewer={setShowItemViewer}
          setModalOpen={setModalOpen}
        />
      </PopUp>
    </div>
  );
}
