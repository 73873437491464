import "./components.css";

export default function Catagory({ name, img, colors, isFlipped }) {
  const image = `https://menuapi.mubatech.com/images/categories/${img}.png`;

  return (
    <div
      className="catagory-container"
      style={{ direction: isFlipped ? "rtl" : "ltr" }}
    >
      <img src={image} className="catagory-img" alt={name} />
      <p className="catagory-name" style={{ color: `${colors.primary_font}` }}>
        {name}
      </p>
    </div>
  );
}
