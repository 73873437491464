import SocialLinks from "./SocialLinks";

export default function MenuFooter({ siteData }) {
  return (
    <footer
      className="footer-container"
      style={{ color: siteData.colors.primary_font }}
    >
      <div className="home-social-links">
        <SocialLinks
          link={siteData.info.fb}
          color={siteData.colors}
          type={"fb"}
        />

        <SocialLinks
          link={siteData.info.insta}
          color={siteData.colors}
          type={"insta"}
        />

        <SocialLinks
          link={siteData.info.snap}
          color={siteData.colors}
          type={"snap"}
        />

        <SocialLinks
          link={siteData.info.youtube}
          color={siteData.colors}
          type={"youtube"}
        />
      </div>
      <div>
        <p>{siteData.info.phone}</p>
        <p>{siteData.info.address}</p>
      </div>
      <p
        className="footer-copy-right"
        style={{ color: siteData.colors.secondary_font }}
      >
        © 2023 By <span style={{ fontWeight: 600 }}> Muba Tech</span>
      </p>
    </footer>
  );
}
